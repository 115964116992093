import React from 'react'
import PressLayout from '../../components/pressLayout'
import Share from '../../components/share'
import { Link } from 'gatsby'

export default function TrexityCanatracePartner () {
  const shareTitle = 'Trexity™ and CANATRACE™ partner to screen all drivers for COVID-19 symptoms'
  const articleLink = 'https://ca.finance.yahoo.com/news/trexity-canatrace-partner-screen-drivers-120000142.html'

  return (
    <PressLayout>
      <section className='story'>
        <div className='row align-center'>
          <div className='col'>
            <div className="inner">
              <h1>Trexity™ and CANATRACE™ partner to screen all drivers for COVID-19 symptoms</h1>

              <header className="article-header">
                <div className="article-details">
                  <span className='news'>News</span>
                  <span>March 18, 2021</span>
                </div>

                <Share shareTitle={shareTitle} />
              </header>

              <div className="article-body">
                <img src="/img/press/canatrace.jpg" alt="Canatrace logo" />
                <div className="snippet">The following preview is from <a href={articleLink} target="_blank" rel='noreferrer'>Yahoo Finance</a></div>
                <p>Trexity today announced a new partnership with CANATRACE. This partnership will help to ensure all drivers on the Trexity platform are screened every day for COVID&#8209;19 symptoms before they are allowed to deliver packages for merchants.</p>
                <p>“Ensuring the safety and health of our drivers and merchants is our top priority,” says Alok Ahuja, CEO and co-founder of Trexity. “By screening our drivers daily for COVID&#8209;19 symptoms we are taking the necessary steps to protect our community.”</p>
                <a href={articleLink} target='_blank' rel='noreferrer'>Read more at Yahoo Finance</a>
              </div>

              <footer className="article-footer">
                <div className="article-next">
                  <Link to="/press/ottawa-teams-up-to-boost-local" className='cta-link'>Related Article <i className="material-icons">keyboard_arrow_right</i></Link>
                  <p>Ottawa Board of Trade, BIA coalition team up on discount, delivery programs to boost local businesses</p>
                </div>

                <Share shareTitle={shareTitle} />
              </footer>
            </div>
          </div>
        </div>
      </section>
    </PressLayout>
  )
}
